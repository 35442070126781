import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Chart from './components/Chart';
import { FullScreenChartModal } from 'components';

import useModal from 'hooks/common/useModal';

import { Granularity } from './constants/granularity';

const DefaultGranularities = Object.values(Granularity);

const HashrateChart = ({
  titleClass,
  title = 'hashrateChart.title',
  column,
  accountId,
  onChangeColumn,
  requestCallback,
  granularities = DefaultGranularities,
  ...props
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [granularity, setGranularity] = useState(column || granularities[0]);

  const { open, onOpen, onClose } = useModal();

  const handleChangeColumn = value => {
    setGranularity(value);

    if (onChangeColumn) {
      onChangeColumn(value);
    }
  };

  const getChartData = useCallback(async () => {
    setLoading(true);

    const result = await requestCallback({
      column: granularity,
    });

    const { data, success } = result || {};

    if (success && data?.type !== 'error') {
      setData(data);
    } else {
      setData(null);
    }

    setLoading(false);
  }, [granularity, requestCallback]);

  useEffect(() => {
    getChartData();
  }, [accountId, getChartData]);

  return (
    <>
      <Chart
        enableExpend
        data={data}
        title={t(title)}
        loading={loading}
        titleClass={titleClass}
        granularity={granularity}
        granularities={granularities}
        onExpand={onOpen}
        onChangeGranularity={handleChangeColumn}
        {...props}
      />

      <FullScreenChartModal title={t(title)} open={open} onClose={onClose}>
        <Chart
          width="100%"
          height="100%"
          data={data}
          loading={loading}
          granularity={granularity}
          granularities={granularities}
          onChangeGranularity={handleChangeColumn}
          {...props}
        />
      </FullScreenChartModal>
    </>
  );
};

export default HashrateChart;
