import React, { useMemo } from 'react';

import { AlertsStack } from 'components';
import Notification from '../Notification';

import { groupByType } from '../../utils/group';
import useNotificationsMutation from '../../hooks/user-notifications/useNotificationsMutation';

import { NotificationType } from '../../constants/types';

const NotificationsGroup = ({ data, onDelete = () => {} }) => {
  const dataGroupedByType = useMemo(() => groupByType(data), [data]);

  const closableNotifications = dataGroupedByType[NotificationType.CLOSABLE] || [];
  const notClosableNotifications = dataGroupedByType[NotificationType.NOT_CLOSABLE] || [];

  const closableAlerts = closableNotifications.map(item => ({ id: item.id, type: 'warning', message: item.msg }));

  const { deleteNotification } = useNotificationsMutation();

  return (
    <>
      {notClosableNotifications.map(notification => (
        <Notification key={notification.id} data={notification} onDelete={onDelete} />
      ))}

      {closableNotifications.length && (
        <AlertsStack alerts={closableAlerts} onDelete={onDelete} deleteRequest={deleteNotification} />
      )}
    </>
  );
};

export default NotificationsGroup;
