import { useTranslation } from 'react-i18next';

import formatDate from 'utils/date/formatDate';
import formatBtcAmount from 'utils/coins/BTC/formatBtcAmount';

const useMyReferralRecordsColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('referralRewards.table.myReferralRecords.columns.account'),
      dataIndex: 'accounts',
      key: 'accounts',
      align: 'center',
      width: 100,
    },
    {
      title: t('referralRewards.table.myReferralRecords.columns.date'),
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      width: 90,
      render: value => formatDate(value, { ms: true }),
    },
    {
      title: t('referralRewards.table.myReferralRecords.columns.cumulativeRewards'),
      dataIndex: 'reward',
      key: 'reward',
      align: 'right',
      width: 100,
      render: value => formatBtcAmount(+value),
    },
  ];
};

export default useMyReferralRecordsColumns;
