import React from 'react';

import { BaseModal } from 'components';

import './FullScreenChartModal.styles.scss';

const FullScreenChartModal = ({ open, children, onClose = () => {}, ...props }) => {
  return (
    <BaseModal fullScreen open={open} onCancel={onClose} className="np-full-screen-chart-modal" {...props}>
      {children}
    </BaseModal>
  );
};

export default FullScreenChartModal;
