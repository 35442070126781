import formatBtcAmount from 'utils/coins/BTC/formatBtcAmount';
import { useTranslation } from 'react-i18next';

export const useOverallReferralOptions = data => {
  const { t } = useTranslation();

  return [
    {
      title: t('referralRewards.overallReferral.param.overallReferral'),
      value: data?.ref_acc_number || 0,
    },
    {
      title: t('referralRewards.overallReferral.param.cumulativeReferredAccounts'),
      value: data?.ref_30d || 0,
    },
    {
      title: t('referralRewards.overallReferral.param.cumulativeReferralReward'),
      value: data?.total_reward ? formatBtcAmount(data?.total_reward) : 0,
    },
  ];
};
