import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoItem from './components/InfoItem';

import formatCoinAmount from 'utils/coins/common/formatCoinAmount';

import { CoinIcon, CoinLabel } from 'constants/coins';
import { MinPaymentAmount, AUTO_WITHDRAWAL_TIME } from 'constants/payment';

import './PaymentSettingsInfo.styles.scss';

const PaymentSettingsInfo = ({ coin, data, loading }) => {
  const { t } = useTranslation();

  const coinIcon = CoinIcon[coin];
  const coinLabel = CoinLabel[coin];

  return (
    <div className="np-payment-settings-info">
      <InfoItem
        title={t('payment.paymentSettings.balance', { coin: coinLabel })}
        titleIcon={<img src={coinIcon} alt="coin" />}
        value={formatCoinAmount(data?.balance, coin)}
        loading={loading}
      />

      <InfoItem
        title={t('payment.paymentSettings.minimalPayment', { coin: coinLabel })}
        value={formatCoinAmount(MinPaymentAmount[coin], coin, { round: false })}
      />

      <InfoItem title={'payment.paymentSettings.paymentTime'} value={AUTO_WITHDRAWAL_TIME} />
    </div>
  );
};

export default PaymentSettingsInfo;
