import { getAddressInfo } from 'bitcoin-address-validation';
import { BtcAddressType, CryptoNetwork } from 'constants/wallet';

const checkIsBtcWalletValid = (wallet, options) => {
  if (!wallet) {
    return false;
  }

  const { networks = Object.values(CryptoNetwork), types = Object.values(BtcAddressType) } = options || {};

  try {
    const walletInfo = getAddressInfo(wallet);

    const isValidWallet = types.includes(walletInfo.type);
    const isValidNetwork = networks.includes(walletInfo.network);

    return isValidWallet && isValidNetwork;
  } catch (error) {
    return false;
  }
};

export default checkIsBtcWalletValid;
