import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import loadable from '@loadable/component';

import { UserLayout, WatcherLayout, PageLoader, UserSidebarLayout, WatcherSidebarLayout } from 'components';
import {
  ProtectedPrivateContainer,
  ProtectedPublicContainer,
  ProtectedWatcherContainer,
  PublicContainer,
  WatcherAutorizeFailResult,
} from 'modules';

import useUrlLang from 'hooks/common/useUrlLang';

import { captchaKey } from 'configs';
import { publicUrls } from 'constants/urls';
import { RouteWatcherPermissions } from 'constants/observer/permissions';

import { LayoutBackgroundType } from 'components/layouts/user/BaseLayout';

const Landing = loadable(() => import('../pages/LandingPage'), {
  fallback: <PageLoader />,
});

const LoginPage = loadable(() => import('../pages/LoginPage'), {
  fallback: <PageLoader />,
});

const SignUpPage = loadable(() => import('../pages/SignUpPage'), {
  fallback: <PageLoader />,
});

const SignUpResultPage = loadable(() => import('../pages/SignUpResultPage'), {
  fallback: <PageLoader />,
});

const ForgotPasswordPage = loadable(() => import('../pages/ForgotPasswordPage'), {
  fallback: <PageLoader />,
});

const NewPasswordPage = loadable(() => import('../pages/NewPasswordPage'), {
  fallback: <PageLoader />,
});

const RegistrationPage = loadable(() => import('../pages/RegistrationPage'), {
  fallback: <PageLoader />,
});

const LogoutPage = loadable(() => import('../pages/LogoutPage'), {
  fallback: <PageLoader />,
});

const BetaTestApplicationPage = loadable(() => import('../pages/BetaTestApplicationPage'), {
  fallback: <PageLoader />,
});

const NotFoundPage = loadable(() => import('../pages/NotFoundPage'), {
  fallback: <PageLoader />,
});

// Users Routes

const DashboardPage = loadable(() => import('../pages/user/DashboardPage'), {
  fallback: <PageLoader />,
});

const AccountSettingsPage = loadable(() => import('../pages/user/AccountSettingsPage'), {
  fallback: <PageLoader />,
});

const ApiManagementPage = loadable(() => import('../pages/user/ApiManagementPage'), {
  fallback: <PageLoader />,
});

const MyBillsPage = loadable(() => import('../pages/user/MyBillsPage'), {
  fallback: <PageLoader />,
});

const ReferralRewardsPage = loadable(() => import('../pages/user/ReferralRewardsPage'), {
  fallback: <PageLoader />,
});

const VipApplicationPage = loadable(() => import('../pages/user/VipApplicationPage'), {
  fallback: <PageLoader />,
});

const WorkersPage = loadable(() => import('../pages/user/WorkersPage'), {
  fallback: <PageLoader />,
});

const MiningAccountsPage = loadable(() => import('../pages/user/MiningAccountsPage'), {
  fallback: <PageLoader />,
});

const PaymentSettingsPage = loadable(() => import('../pages/user/PaymentSettingsPage'), {
  fallback: <PageLoader />,
});

const MiningSettingsPage = loadable(() => import('../pages/user/MiningSettingsPage'), {
  fallback: <PageLoader />,
});

const WatcherPage = loadable(() => import('../pages/user/WatcherPage'), {
  fallback: <PageLoader />,
});

const NotificationsPage = loadable(() => import('../pages/user/NotificationsPage'), {
  fallback: <PageLoader />,
});

const ResetPaymentPasswordPage = loadable(() => import('../pages/user/ResetPaymentPasswordPage'), {
  fallback: <PageLoader />,
});

const ResetGaPage = loadable(() => import('../pages/user/ResetGaPage'), {
  fallback: <PageLoader />,
});

const ReferralRewardsIntroductionPage = loadable(() => import('../pages/ReferralRewardsIntroductionPage'), {
  fallback: <PageLoader />,
});

const DepositAndWithdrawalPage = loadable(() => import('../pages/user/DepositAndWithdrawalPage'), {
  fallback: <PageLoader />,
});

// Watchers Routes

const WatcherDashboard = loadable(() => import('../pages/observer/DashboardPage'), {
  fallback: <PageLoader />,
});

const WatcherMiningAccounts = loadable(() => import('../pages/observer/MiningAccountsPage'), {
  fallback: <PageLoader />,
});

const WatcherMiningSettings = loadable(() => import('../pages/observer/MiningSettingsPage'), {
  fallback: <PageLoader />,
});

const WatcherAccountSettings = loadable(() => import('../pages/observer/AccountSettingsPage'), {
  fallback: <PageLoader />,
});

const WatcherWorkers = loadable(() => import('../pages/observer/WorkersPage'), {
  fallback: <PageLoader />,
});

const WatcherBills = loadable(() => import('../pages/observer/MyBillsPage'), {
  fallback: <PageLoader />,
});

const WatcherNotificationsPage = loadable(() => import('../pages/observer/NotificationsPage'), {
  fallback: <PageLoader />,
});

const WatcherObserverPage = loadable(() => import('../pages/observer/WatcherPage'), {
  fallback: <PageLoader />,
});

const WatcherPaymentSettingsPage = loadable(() => import('../pages/observer/PaymentSettingsPage'), {
  fallback: <PageLoader />,
});

// const WatcherApiManagementPage = loadable(() => import('./pages/observer/ApiManagementPage'), {
//   fallback: <PageLoader />,
// });

const WatcherReferralRewardsPage = loadable(() => import('../pages/observer/ReferralRewardsPage'), {
  fallback: <PageLoader />,
});

const WatcherDepositAndWithdrawalPage = loadable(() => import('../pages/observer/DepositAndWithdrawalPage'), {
  fallback: <PageLoader />,
});

const WatcherNotFound = loadable(() => import('../pages/observer/NotFoundPage'), {
  fallback: <PageLoader />,
});

const {
  main,
  login,
  singUp,
  singUpResult,
  forgotPassword,
  resetPassword,
  emailConfirm,
  logout,

  profile,
  observer,

  resetGa,
  vipApplication,
  referralRewardsIntroduction,
  betaTestApplication,
} = publicUrls;

const NPRoutes = () => {
  useUrlLang();

  return (
    <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey={captchaKey}>
      <div style={{ flex: '1 0 auto' }}>
        <Routes>
          <Route
            path={main}
            element={
              <ProtectedPublicContainer>
                <UserLayout showLogo showFooter backgroundType={LayoutBackgroundType.LOOPER}>
                  <Outlet />
                </UserLayout>
              </ProtectedPublicContainer>
            }
          >
            <Route index element={<Landing />} />
          </Route>

          <Route
            path={main}
            element={
              <ProtectedPublicContainer>
                <UserLayout showLogo showFooter>
                  <Outlet />
                </UserLayout>
              </ProtectedPublicContainer>
            }
          >
            <Route path={login} element={<LoginPage />} />
            <Route path={singUp} element={<SignUpPage />} />
            <Route path={singUpResult} element={<SignUpResultPage />} />
            <Route path={forgotPassword} element={<ForgotPasswordPage />} />
            <Route path={resetPassword} element={<NewPasswordPage />} />
          </Route>

          <Route
            path={profile.main}
            element={
              <ProtectedPrivateContainer saveUrlOnFail>
                <UserSidebarLayout>
                  <UserLayout>
                    <Outlet />
                  </UserLayout>
                </UserSidebarLayout>
              </ProtectedPrivateContainer>
            }
          >
            <Route index element={<DashboardPage />}></Route>

            <Route path={profile.accountManagement.main}>
              <Route path={profile.accountManagement.miningAccounts} element={<MiningAccountsPage />}></Route>
              <Route path={profile.accountManagement.watcher} element={<WatcherPage />}></Route>
              <Route path={profile.accountManagement.notifications} element={<NotificationsPage />}></Route>
            </Route>

            <Route path={profile.apiManagement.main}>
              <Route path={profile.apiManagement.apiKeys} element={<ApiManagementPage />}></Route>
            </Route>

            <Route path={profile.settings.main}>
              <Route path={profile.settings.account} element={<AccountSettingsPage />}></Route>
              <Route path={profile.settings.mining} element={<MiningSettingsPage />}></Route>
              <Route path={profile.settings.payment} element={<PaymentSettingsPage />}></Route>
            </Route>

            <Route path={profile.wallet.main}>
              <Route path={profile.wallet.myBills} element={<MyBillsPage />}></Route>
              <Route path={profile.wallet.depositAndWithdrawal} element={<DepositAndWithdrawalPage />}></Route>
            </Route>

            <Route path={profile.workers} element={<WorkersPage />}></Route>
            <Route path={profile.referralRewards} element={<ReferralRewardsPage />}></Route>
            <Route path={profile.resetPaymentPassword} element={<ResetPaymentPasswordPage />}></Route>
          </Route>

          {/* Watcher Routes */}

          <Route
            path={observer.profile.main}
            element={
              <WatcherSidebarLayout>
                <WatcherLayout>
                  <Outlet />
                </WatcherLayout>
              </WatcherSidebarLayout>
            }
          >
            <Route
              index
              element={
                <ProtectedWatcherContainer fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}>
                  <WatcherDashboard />
                </ProtectedWatcherContainer>
              }
            ></Route>

            <Route
              path={observer.profile.workers}
              element={
                <ProtectedWatcherContainer
                  {...RouteWatcherPermissions[observer.profile.workers]}
                  fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                >
                  <WatcherWorkers />
                </ProtectedWatcherContainer>
              }
            ></Route>

            <Route
              path={observer.profile.wallet.main}
              element={
                <ProtectedWatcherContainer
                  {...RouteWatcherPermissions[observer.profile.wallet.main]}
                  fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                >
                  <Outlet />
                </ProtectedWatcherContainer>
              }
            >
              <Route path={observer.profile.wallet.myBills} element={<WatcherBills />}></Route>
              <Route
                path={observer.profile.wallet.depositAndWithdrawal}
                element={<WatcherDepositAndWithdrawalPage />}
              ></Route>
            </Route>

            <Route path={observer.profile.accountManagement.main}>
              <Route
                path={observer.profile.accountManagement.miningAccounts}
                element={
                  <ProtectedWatcherContainer
                    {...RouteWatcherPermissions[observer.profile.accountManagement.miningAccounts]}
                    fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                  >
                    <WatcherMiningAccounts />
                  </ProtectedWatcherContainer>
                }
              ></Route>

              <Route
                path={observer.profile.accountManagement.notifications}
                element={
                  <ProtectedWatcherContainer
                    {...RouteWatcherPermissions[observer.profile.accountManagement.notifications]}
                    fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                  >
                    <WatcherNotificationsPage />
                  </ProtectedWatcherContainer>
                }
              ></Route>

              {/* <Route
                path={apiManagement}
                element={
                  <ProtectedWatcherContainer
                    requiredPermissions={[WatcherPermissions.ADMIN]}
                    fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                  >
                    <WatcherApiManagementPage />
                  </ProtectedWatcherContainer>
                }
              ></Route> */}

              <Route
                path={observer.profile.accountManagement.watcher}
                element={
                  <ProtectedWatcherContainer
                    {...RouteWatcherPermissions[observer.profile.accountManagement.watcher]}
                    fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                  >
                    <WatcherObserverPage />
                  </ProtectedWatcherContainer>
                }
              ></Route>
            </Route>

            <Route path={observer.profile.settings.main}>
              <Route
                path={observer.profile.settings.account}
                element={
                  <ProtectedWatcherContainer
                    {...RouteWatcherPermissions[observer.profile.settings.account]}
                    fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                  >
                    <WatcherAccountSettings />
                  </ProtectedWatcherContainer>
                }
              ></Route>

              <Route
                path={observer.profile.settings.mining}
                element={
                  <ProtectedWatcherContainer
                    {...RouteWatcherPermissions[observer.profile.settings.mining]}
                    fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                  >
                    <WatcherMiningSettings />
                  </ProtectedWatcherContainer>
                }
              ></Route>

              <Route
                path={observer.profile.settings.payment}
                element={
                  <ProtectedWatcherContainer
                    {...RouteWatcherPermissions[observer.profile.settings.payment]}
                    fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                  >
                    <WatcherPaymentSettingsPage />
                  </ProtectedWatcherContainer>
                }
              ></Route>
            </Route>

            <Route
              path={observer.profile.referralRewards}
              element={
                <ProtectedWatcherContainer
                  {...RouteWatcherPermissions[observer.profile.referralRewards]}
                  fallbackOnAuthorizationFail={<WatcherAutorizeFailResult />}
                >
                  <WatcherReferralRewardsPage />
                </ProtectedWatcherContainer>
              }
            ></Route>
          </Route>

          {/* Common */}

          <Route
            path={resetGa}
            element={
              <PublicContainer>
                <UserLayout showLogo showFooter>
                  <ResetGaPage />
                </UserLayout>
              </PublicContainer>
            }
          ></Route>

          <Route
            path={emailConfirm}
            element={
              <PublicContainer>
                <UserLayout showLogo showFooter>
                  <RegistrationPage />
                </UserLayout>
              </PublicContainer>
            }
          />

          <Route
            path={logout}
            element={
              <PublicContainer>
                <LogoutPage />
              </PublicContainer>
            }
          />

          <Route
            path={main}
            element={
              <PublicContainer saveUrlOnFail>
                <UserLayout showLogo showFooter disableStickHeader backgroundType={LayoutBackgroundType.GRADIENT}>
                  <Outlet />
                </UserLayout>
              </PublicContainer>
            }
          >
            <Route path={vipApplication} element={<VipApplicationPage />} />
            <Route path={referralRewardsIntroduction} element={<ReferralRewardsIntroductionPage />} />
            <Route path={betaTestApplication} element={<BetaTestApplicationPage />} />
          </Route>

          <Route
            path={observer.invalidWatcherLink}
            element={
              <PublicContainer>
                <UserLayout showLogo showFooter>
                  <WatcherNotFound />
                </UserLayout>
              </PublicContainer>
            }
          />

          <Route
            path="*"
            element={
              <PublicContainer>
                <UserLayout showLogo showFooter>
                  <NotFoundPage />
                </UserLayout>
              </PublicContainer>
            }
          />
        </Routes>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default NPRoutes;
