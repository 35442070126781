import { ClassPrefix, StackedAlertIndex } from '../constants/alerts';

const getAnimationClass = index => {
  switch (index) {
    case StackedAlertIndex.TOP:
      return `${ClassPrefix}--remove-animation-top`;

    case StackedAlertIndex.MIDDLE:
      return `${ClassPrefix}--remove-animation-middle`;

    case StackedAlertIndex.BOTTOM:
      return `${ClassPrefix}--remove-animation-bottom`;

    default:
      return '';
  }
};

export default getAnimationClass;
