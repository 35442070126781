import React from 'react';
import { FullscreenOutlined } from '@ant-design/icons';

import { BlockWrapper, IconButton, Loading, RadioGroup, Chart as NpChart } from 'components';

import useChartOptions from './hooks/useChartOptions';
import { useFilterOptions } from './hooks/useFilterOptions';
import useWindowResponsiveness from 'hooks/common/useWindowResponsiveness';

import './Chart.styles.scss';

const Chart = ({
  data,
  loading,
  granularity,
  granularities,
  titleClass = '',
  title,
  width,
  height,
  enableExpend,
  onExpand = () => {},
  onChangeGranularity = () => {},
  ...props
}) => {
  const { isMobileView } = useWindowResponsiveness(500);

  const filterOptions = useFilterOptions(granularities);
  const chartOptions = useChartOptions(data, { granularity });

  return (
    <BlockWrapper {...props}>
      <div className="np-hashrate-chart__header">
        <div className="np-hashrate-chart__header-title">
          <BlockWrapper.Title className={titleClass}>{title}</BlockWrapper.Title>

          {isMobileView && enableExpend && (
            <IconButton icon={<FullscreenOutlined />} tooltip="tooltip.expand" onClick={onExpand} />
          )}
        </div>

        <div className="np-hashrate-chart__actions">
          <RadioGroup
            size="small"
            value={granularity}
            options={filterOptions}
            onChange={onChangeGranularity}
            className="np-hashrate-chart__switchers"
          />

          {!isMobileView && (
            <div>
              {enableExpend && <IconButton icon={<FullscreenOutlined />} tooltip="tooltip.expand" onClick={onExpand} />}
            </div>
          )}
        </div>
      </div>

      <Loading isLoading={loading} isEmpty={!loading && !data}>
        {chartOptions && !loading ? <NpChart width={width} height={height} option={chartOptions} /> : null}
      </Loading>
    </BlockWrapper>
  );
};

export default Chart;
