import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullscreenOutlined } from '@ant-design/icons';

import { BlockWrapper, IconButton, Loading, Chart as NpChart } from 'components';

import useChartOptions from './hooks/useChartOptions';

import './Chart.styles.scss';

const Chart = ({ data, loading, title, enableExpend, width, height, onExpand = () => {}, ...props }) => {
  const { t } = useTranslation();

  const options = useChartOptions(data);

  const noData = !data || !data?.series?.length || !data?.dates?.length;

  return (
    <BlockWrapper {...props}>
      <div className="np-profit-chart__header">
        <BlockWrapper.Title className="np-profit-chart__title">{t(title)}</BlockWrapper.Title>

        {enableExpend && <IconButton icon={<FullscreenOutlined />} tooltip="tooltip.expand" onClick={onExpand} />}
      </div>

      <Loading isLoading={loading} isEmpty={!loading && noData}>
        <NpChart width={width} height={height} option={options} />
      </Loading>
    </BlockWrapper>
  );
};

export default Chart;
