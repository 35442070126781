import React from 'react';
import { Alert, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Spin } from 'components';

import getPositionClass from './utils/getPositionClass';
import getAnimationClass from './utils/getAnimationClass';

import { StackedAlertIndex } from './constants/alerts';

import './StackedAlert.styles.scss';

const { Text } = Typography;

const StackedAlert = ({
  alert,
  loading,
  position,
  animation,
  alertsLength,
  animationDuration,
  onDelete = () => {},
  ...props
}) => {
  const isTop = position === StackedAlertIndex.TOP;
  const positionClass = getPositionClass(position);
  const animationClass = getAnimationClass(position);

  const zIndexTop = isTop ? alertsLength : null;
  const zIndexOther = alertsLength - position;

  const animationClassTop = isTop && animation ? animationClass : '';
  const animationClassOthers = !isTop && animation ? animationClass : '';

  const { id, type, message } = alert || {};

  const alertMessage = isTop ? message : <Text ellipsis>{message}</Text>;

  const AlertComponent = (
    <Alert
      showIcon
      key={id}
      type={type}
      message={alertMessage}
      style={{ zIndex: zIndexTop, animationDuration: `${animationDuration}ms` }}
      action={loading && isTop ? <Spin size="small" /> : <CloseOutlined onClick={() => onDelete(id)} />}
      className={`${animationClassTop} ${positionClass}`}
      {...props}
    />
  );

  if (isTop) {
    return AlertComponent;
  }

  return (
    <div
      className={`np-stacked-alert__wrapper ${animationClassOthers}`}
      style={{ zIndex: zIndexOther, animationDuration: `${animationDuration}ms` }}
    >
      {AlertComponent}
    </div>
  );
};

export default StackedAlert;
