import { useMemo } from 'react';
import { StackedAlertIndex, StackedAlertMarginBottom } from '../components/StackedAlert/constants/alerts';

const useStackMarginBottom = length => {
  return useMemo(() => {
    if (length > StackedAlertIndex.BOTTOM) {
      return StackedAlertMarginBottom[StackedAlertIndex.BOTTOM];
    }

    if (length > StackedAlertIndex.MIDDLE) {
      return StackedAlertMarginBottom[StackedAlertIndex.MIDDLE];
    }

    return StackedAlertMarginBottom[StackedAlertIndex.TOP];
  }, [length]);
};

export default useStackMarginBottom;
