import _isEmpty from 'lodash/isEmpty';

import request from './request.service';
import { ErrorType } from 'constants/errors';
import { StorageKey } from 'constants/localStorage';
import { apiUrls, publicUrls } from 'constants/urls';

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
  /**
   * Remove an item from the used storage
   * @param  {String} key [description]
   */
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  /**
   * Clear all app storage
   */
  clearAppStorage() {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  },

  clearTokens() {
    auth.clear(StorageKey.ACCESS_TOKEN);
    auth.clear(StorageKey.REFRESH_TOKEN);
  },

  /**
   * Returns data from storage
   * @param  {String} key Item to get from the storage
   * @return {String|Object}     Data from the storage
   */
  get(key) {
    if (localStorage && localStorage.getItem(key)) {
      return parse(localStorage.getItem(key)) || null;
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return parse(sessionStorage.getItem(key)) || null;
    }

    return null;
  },
  /**
   * Set data in storage
   * @param {String|Object}  value    The data to store
   * @param {String}  key
   * @param {Boolean} isLocalStorage  Defines if we need to store in localStorage or sessionStorage
   */
  set(value, key, isLocalStorage) {
    if (_isEmpty(value)) {
      return null;
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    return null;
  },

  getAccessToken() {
    return auth.get(StorageKey.ACCESS_TOKEN);
  },

  getRefreshToken() {
    return auth.get(StorageKey.REFRESH_TOKEN);
  },

  setRefreshToken(value = '', options) {
    const { isLocalStorage = true } = options || {};

    return auth.set(value, StorageKey.REFRESH_TOKEN, isLocalStorage);
  },

  setAccessToken(value = '', options) {
    const { isLocalStorage = true, disableReload } = options || {};

    if (disableReload) {
      localStorage.setItem(StorageKey.DISABLE_RELOAD_USER, true);
    }

    auth.set(value, StorageKey.ACCESS_TOKEN, isLocalStorage);
  },

  async login({ email = null, password = null, ...params }) {
    if (!email || !password) {
      throw new Error('Provide Email and Password!');
    }
    auth.clearTokens();
    const result = await request(apiUrls.auth.login, {
      method: 'POST',
      body: {
        email,
        password,
        ...params,
      },
    });

    if (result?.message === ErrorType.REQUIRED_2FA) {
      return ErrorType.REQUIRED_2FA;
    } else if (result.access_token && result.refresh_token) {
      auth.setAccessToken(result.access_token);
      auth.setRefreshToken(result.refresh_token);
    } else {
      throw new Error();
    }
  },

  async login2FA(body) {
    auth.clearTokens();

    const result = await request(apiUrls.auth.login2FA, {
      method: 'POST',
      body,
    });

    if (result.access_token && result.refresh_token) {
      auth.setAccessToken(result.access_token);
      auth.setRefreshToken(result.refresh_token);
    } else {
      throw new Error();
    }
  },

  async recoveryLogin(body) {
    auth.clearTokens();

    const result = await request(apiUrls.auth.recoveryLogin, {
      method: 'POST',
      body,
    });

    if (result.access_token && result.refresh_token) {
      auth.setAccessToken(result.access_token);
      auth.setRefreshToken(result.refresh_token);
    } else {
      throw new Error();
    }
  },
  async checkUserAuth(options = {}) {
    try {
      const result = await request(apiUrls.auth.currentUser, options);
      return !!result;
    } catch (err) {
      return false;
    }
  },

  async logout() {
    try {
      await request(apiUrls.auth.logout, {
        method: 'DELETE',
      });
    } catch (err) {
      console.log(err);
    } finally {
      auth.clearTokens();
      window.location.href = publicUrls.main;
    }
  },
};

export default auth;
