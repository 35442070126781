import { ClassPrefix, StackedAlertIndex } from '../constants/alerts';

const getPositionClass = index => {
  switch (index) {
    case StackedAlertIndex.TOP:
      return `${ClassPrefix}--top`;

    case StackedAlertIndex.MIDDLE:
      return `${ClassPrefix}--middle`;

    default:
      return `${ClassPrefix}--bottom`;
  }
};

export default getPositionClass;
