export const StackedAlertIndex = {
  TOP: 0,
  MIDDLE: 1,
  BOTTOM: 2,
};

const MarginBottomStep = 7;

export const StackedAlertMarginBottom = {
  [StackedAlertIndex.TOP]: StackedAlertIndex.TOP * MarginBottomStep,
  [StackedAlertIndex.MIDDLE]: StackedAlertIndex.MIDDLE * MarginBottomStep,
  [StackedAlertIndex.BOTTOM]: StackedAlertIndex.BOTTOM * MarginBottomStep,
};

export const ClassPrefix = 'np-stacked-alert';
