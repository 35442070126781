import React from 'react';
import { Descriptions, Spin } from 'antd';

import './TableList.styles.scss';

const DEFAULT_COLUMNS_NUMBER = 1;

const TableList = ({
  dataSource,
  columns = [],
  column = DEFAULT_COLUMNS_NUMBER,
  layout,
  loading,
  title,
  className,
  ...props
}) => {
  const layoutClass = layout === 'vertical' ? 'np-table-list--vertical' : '';

  const titleComponent = title ? (
    <div className="np-table-list__title-wrapper">
      <span>{title}</span>
      {loading && <Spin />}
    </div>
  ) : null;

  return (
    <div className="np-table-list__wrapper">
      {loading && (
        <div className="np-table-list__backdrop">
          <Spin size="large" className="np-table-list__spin" />
        </div>
      )}

      <Descriptions
        bordered
        column={column}
        layout={layout}
        title={titleComponent}
        size="small"
        className={`np-table-list ${layoutClass} ${className}`}
        {...props}
      >
        {columns.map(column => (
          <Descriptions.Item
            key={column.dataIndex}
            label={column.title}
            className={column.align === 'top' ? 'np-table-list--align-top' : ''}
          >
            {column.render && dataSource ? column.render(dataSource) : dataSource?.[column.dataIndex]}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>
  );
};

export default TableList;
