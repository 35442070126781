import { Coin } from 'constants/coins';
import formatBtcAmount from 'utils/coins/BTC/formatBtcAmount';

const formatCoinAmount = (amount, coin, options) => {
  switch (coin) {
    case Coin.BTC:
    case Coin.FB:
      return formatBtcAmount(amount, options);

    default:
      return amount;
  }
};

export default formatCoinAmount;
