import { DefaultFontFamily } from './font';

export const DefaultColors = {
  DIAMOND: '#B9E9FF',
  DIAMOND_DARK: '#a3e2ff',
  LIGHT_SILVER: '#D9D9D9',
  LIGHT_SILVER_3: 'rgba(217, 217, 217, 0.03)',
  LIGHT_SILVER_20: 'rgba(217, 217, 217, 0.20)',
  DARK_SILVER: '#5b5b5b',
  SPANISH_GRAY: '#949495',
  MEDIUM_GRAY: '#C4C4C4',
  LIGHT_GRAY: '#fafafa',
  DARK_CERULEAN: '#12516D',
  MAASTRICHT_BLUE: '#0D2535',
  CRAYOLA_BLUE: '#2F7AF3',
  MAXIMUM_BLUE: '#3aa6d1',
  ALICE_BLUE: '#F1F6FC',
  SMOKY_BLACK: '#30353a',
  VIVID_LIME_GREEN: '#B2D818',
  VIVID_LIME_GREEN_15: 'rgb(178, 216, 24, 0.15)',
  CHARLESTON_GREEN: '#282828',
  BATTERY_CHARGED_BLUE: '#189CD8',
  BATTERY_CHARGED_BLUE_5: 'rgba(24, 156, 216, 0.05)',
  BATTERY_CHARGED_BLUE_10: 'rgba(24, 156, 216, 0.10)',
  BATTERY_CHARGED_BLUE_20: 'rgba(24, 156, 216, 0.20)',
  BATTERY_CHARGED_BLUE_30: 'rgba(24, 156, 216, 0.30)',
  BATTERY_CHARGED_BLUE_50: 'rgba(24, 156, 216, 0.50)',
  BATTERY_CHARGED_BLUE_70: 'rgba(24, 156, 216, 0.70)',
  BATTERY_CHARGED_BLUE_DARK: '#072f41',
  BATTERY_CHARGED_BLUE_EXTRADARK: '#062736',
  EERIE_BLACK: '#1E1E1E',
  EERIE_BLACK_DARK: '#191919',
  EXTRADARK_GRAY: '#1D1D1B',
  CHINESE_BLACK: '#141414',
  BRIGHT_GRAY: '#ECECEC',
  ARSENIC: '#424242',
  CULTURED: '#F6F7F8',
  GREEN_LIGHT: '#CCE6D6',
  GREEN_MEDIUM_2: '#008234',
  GREEN_DARK: '#233028',
  YELLOW_LIGHT: '#FFF4DF',
  YELLOW_MEDIUM: '#F09C00',
  YELLOW_DARK: '#362f22',
  PURPLE_LIGHT: '#e6d9ff',
  PURPLE_MEDIUM: '#9352b5',
  PURPLE_DARK: '#3b324c',
  RED_MEDIUM: '#f06b76',
  RED_LIGHT: '#FEDCDF',
  RED_MEDIUM_2: '#F94E5D',
  RED_DARK: '#442c2e',
  ORANGE_LIGHT: '#fedec1',
  ORANGE_MEDIUM_2: '#c45e04',
  ORANGE_DARK: '#372e26',
  CRIMSON_LIGHT: '#fedcf5',
  CRIMSON_MEDIUM: '#bf2559',
  CRIMSON_DARK: '#492740',
  BLUE_EXTRALIGHT: '#dff2f7',
  BLUE_LIGHT: '#c1ebff',
  BLUE_DARK: '#24343b',
  BLUE_PRE_DARK: '#41555f',
  RED_VENETIAN: '#FF0014',
  LAVA: '#CF1322',
  ORANGE_MEDIUM: '#FFA940',
  LIGHT_CARMINE_PINK: '#e86e6b',
  VENETIAN_RED: '#ff7875',
  ERROR_RED: '#DC4446',
  TUSSOCK: '#BE9941',
  WHITE: '#FFFFFF',
  WHITE_5: 'rgba(255, 255, 255, 0.05)',
  WHITE_4: 'rgba(255, 255, 255, 0.04)',
  WHITE_10: 'rgba(255, 255, 255, 0.10)',
  WHITE_25: 'rgba(255, 255, 255, 0.25)',
  WHITE_40: 'rgba(255, 255, 255, 0.40)',
  WHITE_50: 'rgba(255, 255, 255, 0.50)',
  DARK_GRAY_50: 'rgba(25, 25, 25, 0.50)',
  BLACK_OLIVE: '#3F3F3F',
  BLACK_OLIVE_DARK: '#323232',
  BLACK_60: 'rgba(0, 0, 0, 0.60)',
  BLACK_40: 'rgba(0, 0, 0, 0.40)',
  BLACK_25: 'rgba(0, 0, 0, 0.25)',
  BLACK_5: 'rgba(0, 0, 0, 0.05)',
  BLACK_4: 'rgba(0, 0, 0, 0.04)',
  BLACK_3: 'rgba(0, 0, 0, 0.02)',
  BLACK: '#000000',
};

export const PRIMARY_COLOR = DefaultColors.BATTERY_CHARGED_BLUE;
export const PRIMARY_COLOR_5 = DefaultColors.BATTERY_CHARGED_BLUE_5;
export const PRIMARY_COLOR_10 = DefaultColors.BATTERY_CHARGED_BLUE_10;
export const PRIMARY_COLOR_20 = DefaultColors.BATTERY_CHARGED_BLUE_20;
export const PRIMARY_COLOR_50 = DefaultColors.BATTERY_CHARGED_BLUE_50;
export const PRIMARY_COLOR_70 = DefaultColors.BATTERY_CHARGED_BLUE_70;

export const DarkColors = {
  PRIMARY_COLOR,
  PRIMARY_COLOR_5,
  PRIMARY_COLOR_10,
  PRIMARY_COLOR_20,
  PRIMARY_COLOR_50,
  PRIMARY_COLOR_70,

  MAIN_BG_COLOR: DefaultColors.EERIE_BLACK,
  MAIN_BG_COLOR_2: DefaultColors.CHARLESTON_GREEN,
  MAIN_BG_COLOR_3: DefaultColors.EERIE_BLACK_DARK,
  MAIN_BG_COLOR_4: DefaultColors.CHINESE_BLACK,
  MAIN_BG_COLOR_5: DefaultColors.BLACK,
  MAIN_BG_COLOR_6: DefaultColors.BLACK_OLIVE,
  MAIN_BG_COLOR_7: DefaultColors.CHARLESTON_GREEN,
  MAIN_BG_COLOR_8: DefaultColors.EERIE_BLACK,
  MAIN_BG_COLOR_9: DefaultColors.CHARLESTON_GREEN,
  MAIN_BG_COLOR_10: DefaultColors.BLACK,
  MAIN_BG_COLOR_11: DefaultColors.CHARLESTON_GREEN,
  MAIN_BG_COLOR_12: DefaultColors.DARK_SILVER,

  MENU_BG_COLOR: DefaultColors.CHARLESTON_GREEN,
  MENU_ITEM_BG_COLOR: DefaultColors.BLACK_OLIVE_DARK,
  MENU_ITEM_BORDER_COLOR: DefaultColors.LIGHT_SILVER_20,

  YELLOW_BG_COLOR: DefaultColors.YELLOW_DARK,
  RED_BG_COLOR: DefaultColors.RED_DARK,
  GREEN_BG_COLOR: DefaultColors.GREEN_DARK,
  ORANGE_BG_COLOR: DefaultColors.ORANGE_DARK,
  BLUE_BG_COLOR: DefaultColors.BLUE_DARK,
  BLUE_LIGHT_BG_COLOR: DefaultColors.BLUE_PRE_DARK,
  BLUE_INVERSE_BG_COLOR: DefaultColors.BLUE_LIGHT,
  CRIMSON_BG_COLOR: DefaultColors.CRIMSON_DARK,
  PURPLE_BG_COLOR: DefaultColors.PURPLE_DARK,

  MAIN_TEXT_COLOR: DefaultColors.WHITE,
  MAIN_ICON_COLOR: DefaultColors.EXTRADARK_GRAY,
  ACCENT_TEXT_COLOR: DefaultColors.ORANGE_MEDIUM,

  PRIMARY_DESC_COLOR: DefaultColors.DIAMOND,
  RED_DESC_COLOR: DefaultColors.RED_VENETIAN,
  DEFAULT_DESC_COLOR: DefaultColors.LIGHT_SILVER,
  DEFAULT_DESC_COLOR_2: DefaultColors.WHITE_40,
  DEFAULT_DESC_COLOR_3: DefaultColors.WHITE,
  DEFAULT_DESC_COLOR_4: DefaultColors.SPANISH_GRAY,

  PRIMARY_BUTTON_TEXT_COLOR: DefaultColors.EXTRADARK_GRAY,
  PRIMARY_BUTTON_TEXT_DISABLED_COLOR: DefaultColors.WHITE_40,
  DEFAULT_BUTTON_TEXT_COLOR: DefaultColors.WHITE,
  DEFAULT_BUTTON_BORDER_COLOR: DefaultColors.ARSENIC,

  PRIMARY_BANNER_BG_COLOR: DefaultColors.WHITE_5,

  ACCENT_BANNER_BG_COLOR: DefaultColors.WHITE_5,
  ACCENT_BANNER_TEXT_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,

  POPOVER_BORDER_COLOR: DefaultColors.ARSENIC,
  BANNER_BORDER_COLOR: DefaultColors.LIGHT_SILVER_20,

  FOOTER_LINK_COLOR: DefaultColors.WHITE_50,

  RADIO_BORDER_COLOR: DefaultColors.ARSENIC,

  CHECKBOX_BORDER_COLOR: DefaultColors.LIGHT_SILVER,

  INPUT_BORDER_COLOR: DefaultColors.ARSENIC,
  INPUT_NO_BORDER_COLOR: DefaultColors.EERIE_BLACK,

  ERROR_BORDER_COLOR: DefaultColors.LIGHT_CARMINE_PINK,

  DEFAULT_TAG_BG_COLOR: DefaultColors.CHARLESTON_GREEN,

  DIVIDER_COLOR: DefaultColors.WHITE_10,

  OVERLAY_COLOR: DefaultColors.WHITE_10,
  SELECT_OVERLAY_COLOR: DefaultColors.BATTERY_CHARGED_BLUE_EXTRADARK,

  TAB_BG_COLOR: DefaultColors.BLACK_60,
  TAB_TEXT_COLOR: DefaultColors.SPANISH_GRAY,

  TABLE_ROW_COLOR: DefaultColors.BLACK_OLIVE_DARK,
  TABLE_HEADER_COLOR: DefaultColors.SMOKY_BLACK,
  TABLE_ROW_HOVER_COLOR: DefaultColors.BLACK_OLIVE,
  TABLE_COLUMN_DISABLED_COLOR: DefaultColors.LIGHT_SILVER_3,
  TABLE_HEADER_DISABLED_COLOR: DefaultColors.BLACK_5,
  TABLE_SELECTED_ROW_COLOR: DefaultColors.BATTERY_CHARGED_BLUE_DARK,

  CHART_DIVIDE_LINE_COLOR: DefaultColors.ARSENIC,
  CHART_DIVIDE_LINE_COLOR_2: DefaultColors.WHITE_4,

  SCROLL_TRACK_COLOR: DefaultColors.WHITE_40,
  SCROLL_THUMB_COLOR: DefaultColors.DIAMOND,
  SCROLL_THUMB_ACTIVE_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,
  SCROLL_THUMB_HOVER_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,
};

export const LightColors = {
  PRIMARY_COLOR,
  PRIMARY_COLOR_5,
  PRIMARY_COLOR_10,
  PRIMARY_COLOR_20,
  PRIMARY_COLOR_50,
  PRIMARY_COLOR_70,

  MAIN_BG_COLOR: DefaultColors.CULTURED,
  MAIN_BG_COLOR_2: DefaultColors.WHITE,
  MAIN_BG_COLOR_3: DefaultColors.BRIGHT_GRAY,
  MAIN_BG_COLOR_4: DefaultColors.WHITE,
  MAIN_BG_COLOR_5: DefaultColors.WHITE,
  MAIN_BG_COLOR_6: DefaultColors.CULTURED,
  MAIN_BG_COLOR_7: DefaultColors.BRIGHT_GRAY,
  MAIN_BG_COLOR_8: DefaultColors.WHITE,
  MAIN_BG_COLOR_9: DefaultColors.CULTURED,
  MAIN_BG_COLOR_10: DefaultColors.CULTURED,
  MAIN_BG_COLOR_11: DefaultColors.WHITE,
  MAIN_BG_COLOR_12: DefaultColors.BLACK_60,

  MENU_BG_COLOR: DefaultColors.WHITE,
  MENU_ITEM_BG_COLOR: DefaultColors.CULTURED,
  MENU_ITEM_BORDER_COLOR: DefaultColors.LIGHT_SILVER,

  YELLOW_BG_COLOR: DefaultColors.YELLOW_LIGHT,
  RED_BG_COLOR: DefaultColors.RED_LIGHT,
  GREEN_BG_COLOR: DefaultColors.GREEN_LIGHT,
  ORANGE_BG_COLOR: DefaultColors.ORANGE_LIGHT,
  BLUE_BG_COLOR: DefaultColors.BLUE_LIGHT,
  BLUE_LIGHT_BG_COLOR: DefaultColors.BLUE_EXTRALIGHT,
  BLUE_INVERSE_BG_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,
  CRIMSON_BG_COLOR: DefaultColors.CRIMSON_LIGHT,
  PURPLE_BG_COLOR: DefaultColors.PURPLE_LIGHT,

  MAIN_TEXT_COLOR: DefaultColors.EXTRADARK_GRAY,
  MAIN_ICON_COLOR: DefaultColors.WHITE,
  ACCENT_TEXT_COLOR: DefaultColors.ORANGE_MEDIUM,

  PRIMARY_DESC_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,
  RED_DESC_COLOR: DefaultColors.LAVA,
  DEFAULT_DESC_COLOR: DefaultColors.EXTRADARK_GRAY,
  DEFAULT_DESC_COLOR_2: DefaultColors.DARK_GRAY_50,
  DEFAULT_DESC_COLOR_3: DefaultColors.BLACK_40,
  DEFAULT_DESC_COLOR_4: DefaultColors.EXTRADARK_GRAY,

  PRIMARY_BUTTON_TEXT_COLOR: DefaultColors.WHITE,
  PRIMARY_BUTTON_TEXT_DISABLED_COLOR: DefaultColors.SPANISH_GRAY,
  DEFAULT_BUTTON_TEXT_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,
  DEFAULT_BUTTON_BORDER_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,

  PRIMARY_BANNER_BG_COLOR: DefaultColors.BATTERY_CHARGED_BLUE_20,

  ACCENT_BANNER_BG_COLOR: DefaultColors.VIVID_LIME_GREEN_15,
  ACCENT_BANNER_TEXT_COLOR: DefaultColors.VIVID_LIME_GREEN,

  POPOVER_BORDER_COLOR: DefaultColors.WHITE,
  BANNER_BORDER_COLOR: DefaultColors.BATTERY_CHARGED_BLUE_20,

  FOOTER_LINK_COLOR: DefaultColors.DARK_GRAY_50,

  RADIO_BORDER_COLOR: DefaultColors.LIGHT_SILVER,

  CHECKBOX_BORDER_COLOR: DefaultColors.LIGHT_SILVER,

  INPUT_BORDER_COLOR: DefaultColors.LIGHT_SILVER,
  INPUT_NO_BORDER_COLOR: DefaultColors.LIGHT_SILVER,

  ERROR_BORDER_COLOR: DefaultColors.VENETIAN_RED,

  DEFAULT_TAG_BG_COLOR: DefaultColors.BRIGHT_GRAY,

  DIVIDER_COLOR: DefaultColors.BRIGHT_GRAY,

  OVERLAY_COLOR: DefaultColors.BLACK_5,
  SELECT_OVERLAY_COLOR: DefaultColors.DIAMOND_DARK,

  TAB_BG_COLOR: DefaultColors.LIGHT_SILVER,
  TAB_TEXT_COLOR: DefaultColors.BLACK_60,

  TABLE_ROW_COLOR: DefaultColors.CULTURED,
  TABLE_HEADER_COLOR: DefaultColors.ALICE_BLUE,
  TABLE_ROW_HOVER_COLOR: DefaultColors.LIGHT_GRAY,
  TABLE_COLUMN_DISABLED_COLOR: DefaultColors.BLACK_3,
  TABLE_HEADER_DISABLED_COLOR: DefaultColors.BLACK_5,
  TABLE_SELECTED_ROW_COLOR: DefaultColors.DIAMOND,

  CHART_DIVIDE_LINE_COLOR: DefaultColors.BRIGHT_GRAY,
  CHART_DIVIDE_LINE_COLOR_2: DefaultColors.BLACK_4,

  SCROLL_TRACK_COLOR: DefaultColors.WHITE_40,
  SCROLL_THUMB_COLOR: DefaultColors.DIAMOND,
  SCROLL_THUMB_ACTIVE_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,
  SCROLL_THUMB_HOVER_COLOR: DefaultColors.BATTERY_CHARGED_BLUE,
};

const ThemeTokenCommon = {
  fontFamily: DefaultFontFamily,
};

export const ThemeTokenLight = {
  colorPrimary: PRIMARY_COLOR,
  colorLink: PRIMARY_COLOR,
  colorLinkHover: DefaultColors.BATTERY_CHARGED_BLUE_50,
  colorLinkActive: DefaultColors.BATTERY_CHARGED_BLUE_50,

  colorInfo: DefaultColors.BATTERY_CHARGED_BLUE,
  colorInfoBg: DefaultColors.DIAMOND,
  colorInfoBorder: DefaultColors.BATTERY_CHARGED_BLUE,

  colorSuccess: DefaultColors.GREEN_MEDIUM_2,
  colorSuccessBg: DefaultColors.GREEN_LIGHT,
  colorSuccessBorder: DefaultColors.GREEN_MEDIUM_2,

  colorError: DefaultColors.RED_MEDIUM,
  colorErrorBg: DefaultColors.RED_LIGHT,
  colorErrorBorder: DefaultColors.RED_MEDIUM,

  colorWarning: DefaultColors.YELLOW_MEDIUM,
  colorWarningBg: DefaultColors.YELLOW_LIGHT,
  colorWarningBorder: DefaultColors.YELLOW_MEDIUM,

  ...ThemeTokenCommon,
};

export const ThemeTokenDark = {
  colorPrimary: PRIMARY_COLOR,
  colorLink: PRIMARY_COLOR,
  colorLinkHover: DefaultColors.BATTERY_CHARGED_BLUE_50,
  colorLinkActive: DefaultColors.BATTERY_CHARGED_BLUE_50,

  colorInfo: DefaultColors.BATTERY_CHARGED_BLUE,
  colorInfoBg: DefaultColors.BATTERY_CHARGED_BLUE_DARK,
  colorInfoBorder: DefaultColors.BATTERY_CHARGED_BLUE,

  colorSuccess: DefaultColors.GREEN_MEDIUM_2,
  colorSuccessBg: DefaultColors.GREEN_DARK,
  colorSuccessBorder: DefaultColors.GREEN_MEDIUM_2,

  colorError: DefaultColors.RED_MEDIUM,
  colorErrorBg: DefaultColors.RED_DARK,
  colorErrorBorder: DefaultColors.RED_MEDIUM,

  colorWarning: DefaultColors.YELLOW_MEDIUM,
  colorWarningBg: DefaultColors.YELLOW_DARK,
  colorWarningBorder: DefaultColors.YELLOW_MEDIUM,

  ...ThemeTokenCommon,
};
