import request from './request.service';
import asyncErrorHandler from 'utils/requests/errorHandler';

import { apiUrls } from 'constants/urls';

export const getAccountAvgHashrate = async accountId => {
  const callback = async () => {
    return await request(apiUrls.statistics.hashrateStatsByAcc(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getAccountProfitChart = async accountId => {
  const callback = async () => {
    return await request(apiUrls.charts.profitChart(accountId));
  };

  return await asyncErrorHandler(callback);
};

export const getProfitAndHashrate = async ({ accountId, coin }) => {
  const callback = async () => {
    return await request(apiUrls.statistics.profitTablePg(accountId), {
      params: { coin },
    });
  };

  return await asyncErrorHandler(callback);
};

export const getAccountsSummary = async () => {
  const callback = async () => {
    return await request(apiUrls.accounts.accountSummary);
  };

  return await asyncErrorHandler(callback);
};
