import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';

import useTheme from 'hooks/common/useTheme';
import { serializeData } from '../utils/serializer';
import formatBtcAmount from 'utils/coins/BTC/formatBtcAmount';
import getPositionInside from 'utils/charts/getPositionInside';

const chartGradientColors = {
  from: 'rgba(24, 156, 216, 0.24)',
  to: 'rgba(24, 156, 216, 0.00)',
};

const useChartOptions = (data = {}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const source = serializeData(data);

  return {
    tooltip: {
      trigger: 'axis',
      position: function (pos, params, dom, rect, size) {
        return getPositionInside(pos, size.contentSize, size.viewSize);
      },
    },

    yAxis: {
      type: 'value',
      name: 'BTC',

      splitLine: {
        show: true,
        lineStyle: {
          color: colors.CHART_DIVIDE_LINE_COLOR,
        },
      },
    },
    grid: { left: '3%', right: '7%', bottom: '3%', containLabel: true },
    xAxis: [
      {
        type: 'category',
        // name: '(UTC)',
        nameLocation: 'end',

        nameTextStyle: {
          verticalAlign: 'bottom',
          padding: [0, 0, 0, -50],
        },

        boundaryGap: false,

        splitLine: {
          show: true,
          lineStyle: {
            color: colors.CHART_DIVIDE_LINE_COLOR_2,
          },
        },

        axisLine: {
          show: false,
        },

        axisTick: {
          show: true,
          lineStyle: {
            color: colors.CHART_DIVIDE_LINE_COLOR,
          },
        },
        data: source.dates,
      },
    ],

    series: {
      name: t('profitChart.legendName'),
      type: 'line',
      stack: 'Total',
      smooth: true,

      emphasis: {
        focus: 'series',
      },

      tooltip: {
        valueFormatter: value => `${formatBtcAmount(value)} BTC`,
      },

      lineStyle: {
        color: colors.PRIMARY_COLOR,
      },
      itemStyle: {
        color: colors.PRIMARY_COLOR,
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 0.8, [
          {
            offset: 0,
            color: chartGradientColors.from,
          },
          {
            offset: 1,
            color: chartGradientColors.to,
          },
        ]),
      },
      data: source.series,
    },
  };
};

export default useChartOptions;
