import { Coin } from 'constants/coins';

export const MinPaymentAmounts = {
  [Coin.BTC]: [1, 0.1, 0.01, 0.001],
  [Coin.FB]: [1, 0.5, 0.1],
};

export const MinPaymentAmount = {
  [Coin.BTC]: 0.001,
  [Coin.FB]: 0.1,
};

export const ManualAuditAmount = {
  [Coin.BTC]: 5,
  [Coin.FB]: 500,
};

export const AUTO_WITHDRAWAL_TIME = '7:00-15:00';
export const AUTO_INTERNAL_TRANSFER_TIME = '15:00-16:00';

export const PaymentTransfer = {
  INTER_USER_TRANSFER: 'inter-user-transfer',
  NORMAL_TRANSFER: 'normal-transfer',
};
