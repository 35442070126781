export const Granularity = {
  H5: 'h5',
  H1H: 'h1h',
  H1D: 'h1d',
};

export const GranularityIntervalLabels = {
  [Granularity.H5]: 'hashrateChart.oneDay',
  [Granularity.H1H]: 'hashrateChart.sevenDays',
  [Granularity.H1D]: 'hashrateChart.sixMonths',
};

export const GranularityFrequencyLabels = {
  [Granularity.H5]: 'hashrateChart.tenMin',
  [Granularity.H1H]: 'hashrateChart.oneHour',
  [Granularity.H1D]: 'hashrateChart.oneDay',
};
