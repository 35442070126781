import checkIsBtcWalletValid from 'utils/validation/checkIsBtcWalletValid';

const fbWalletPrefixes = ['bc1q', 'bc1p'];

const checkIsFbWalletValid = (wallet, options) => {
  const hasPrefix = fbWalletPrefixes.some(prefix => wallet.startsWith(prefix));

  return checkIsBtcWalletValid(wallet, options) && hasPrefix;
};

export default checkIsFbWalletValid;
