import React, { useState } from 'react';

import StackedAlert from './components/StackedAlert';

import useStackMarginBottom from './hooks/useStackMarginBottom';

import './AlertsStack.styles.scss';

const DEFAULT_ANIMATION_MS = 200;

const AlertsStack = ({
  alerts,
  animationDelay = DEFAULT_ANIMATION_MS,
  deleteRequest = async () => {},
  onDelete = () => {},
  className = '',
  style,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [animation, setAnimation] = useState(false);

  const alertsLength = alerts.length;

  const marginBottom = useStackMarginBottom(alertsLength);

  const handleDelete = async id => {
    setLoading(true);

    await deleteRequest(id);

    setAnimation(true);

    setTimeout(() => {
      onDelete(id);
      setAnimation(false);
    }, animationDelay);

    setLoading(false);
  };

  return (
    <div style={{ marginBottom, ...style }} className={`np-alerts-stack ${className}`} {...props}>
      {alerts.map((alert, index) => (
        <StackedAlert
          key={alert.id}
          alert={alert}
          position={index}
          loading={loading}
          animation={animation}
          alertsLength={alertsLength}
          animationDuration={animationDelay}
          onDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default AlertsStack;
