import React from 'react';

import './InfoBlock.styles.scss';

const InfoBlock = ({ children, className }) => <div className={`np-info-block ${className}`}>{children}</div>;

InfoBlock.Items = ({ children, noBottomLine }) => (
  <div className={`np-info-block__items ${noBottomLine ? 'np-info-block__items--no-border' : ''}`}>{children}</div>
);

InfoBlock.Item = ({ children, className = '' }) => <div className={`np-info-block__item ${className}`}>{children}</div>;

InfoBlock.Title = ({ children, icon }) => (
  <div className="np-info-block__title">
    {icon}
    <span>{children}</span>
  </div>
);

InfoBlock.Value = ({ children, className = '', ...props }) => (
  <div className={`np-info-block__value ${className}`} {...props}>
    {children}
  </div>
);

export default InfoBlock;
