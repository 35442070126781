import React from 'react';

import './MainContainer.styles.scss';

const MainContainer = ({ children, maxWidth, noPadding, style, className = '', ...props }) => (
  <div
    style={{ maxWidth, padding: noPadding ? 0 : null, ...style }}
    className={`np-main-container ${className ? className : ''}`}
    {...props}
  >
    {children}
  </div>
);

export default MainContainer;
