import { useTranslation } from 'react-i18next';

import formatDate from 'utils/date/formatDate';
import formatBtcAmount from 'utils/coins/BTC/formatBtcAmount';

const useBeneficiaryRewardsTableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('referralRewards.table.myBeneficiaryRewards.columns.account'),
      dataIndex: 'account_name',
      align: 'center',
      width: 70,
    },
    {
      title: t('referralRewards.table.myBeneficiaryRewards.columns.date'),
      dataIndex: 'timestamp',
      align: 'center',
      width: 90,
      render: value => formatDate(value),
    },
    {
      title: t('referralRewards.table.myBeneficiaryRewards.columns.amount'),
      dataIndex: 'amount',
      align: 'right',
      width: 100,
      render: value => formatBtcAmount(+value),
    },
  ];
};

export default useBeneficiaryRewardsTableColumns;
