import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterSelect from '../FilterSelect';

import { Coin, CoinsLabels } from 'constants/coins';

const DefaultCoins = Object.values(Coin);

const CoinFilter = ({ width = 100, minWidth = 100, coins = DefaultCoins, ...props }) => {
  const { t } = useTranslation();

  const options = CoinsLabels.filter(coin => coins.includes(coin.value));

  return (
    <FilterSelect
      allowClear={false}
      options={options}
      popupMatchSelectWidth={false}
      placeholder={t('common.coin')}
      width={width}
      minWidth={minWidth}
      {...props}
    />
  );
};

export default CoinFilter;
