import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Chart from './components/Chart';
import { FullScreenChartModal } from 'components';

import useModal from 'hooks/common/useModal';

const ProfitChart = ({ accountId, requestCallback = () => {}, ...props }) => {
  const { t } = useTranslation();

  const { open, onOpen, onClose } = useModal();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getChartData = async () => {
      setLoading(true);

      const result = await requestCallback();

      const { data, success } = result || {};

      if (success) {
        setData(data);
      } else {
        setData(null);
      }

      setLoading(false);
    };

    getChartData();
  }, [accountId, requestCallback]);

  return (
    <>
      <Chart enableExpend data={data} loading={loading} title={t('profitChart.title')} onExpand={onOpen} {...props} />

      <FullScreenChartModal title={t('profitChart.title')} open={open} onClose={onClose}>
        <Chart data={data} loading={loading} height="100%" width="100%" {...props} />
      </FullScreenChartModal>
    </>
  );
};

export default ProfitChart;
